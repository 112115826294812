@media (min-width: 576px){
    .modal-dialog {
        width: 100%;
        max-width:100%;
        margin: 0;
    }   
}
.close{
    color: #fff !important;
    opacity: 1!important;
}
.span-desc::after{
    content:', ';
}
.span-desc:last-child::after{
    content:'';
}
.modal{
    overflow-y: auto!important;
}