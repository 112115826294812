.bg {
    height: 70vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media screen and (max-width: 767px) {
    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }
  }