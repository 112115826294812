@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,800;1,600&display=swap');
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1c212e!important;
  color:#fff;
}

body, p, a, h1{
    font-family: 'Montserrat', sans-serif;
}



/* .vjs-icon-fullscreen-enter:before, .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before { */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btnTransparent{
    border:none;
    padding:0;
    background-color: rgba(255,255,255,0)!important;
}
