.vjs-quality-selector button.vjs-button>span::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    content: "\f013";
    font-size: 1.5em;
    padding-top:3px;
}

.video-js .vjs-play-control .vjs-icon-placeholder:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    content: "\f144";
    font-size:1.8em;
}
.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before{
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    content: "\f28b";
    font-size:1.8em;
}
.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before{
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    content: "\f424";
}
.video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before{
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    content: "\f422";
}

.video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before{
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    content: "\f6a9";
}
.video-js .vjs-mute-control.vjs-vol-1 .vjs-icon-placeholder:before{
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    content: "\f026";
}
.video-js .vjs-mute-control.vjs-vol-2 .vjs-icon-placeholder:before{
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    content: "\f027";
}
.video-js .vjs-mute-control .vjs-icon-placeholder:before{
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    content: "\f028";
}

.vjs-volume-menu-button .vjs-menu-content {
    transform: rotate(-90deg);
    margin-bottom: 35px !important;
}
.vjs-menu li.vjs-selected, .vjs-menu li.vjs-selected:focus, .vjs-menu li.vjs-selected:hover, .vjs-menu .vjs-menu-content > *{
    padding:8px 3px;
}
.vjs-menu-item-text{
    font-weight: 600;
    text-transform: uppercase;
}

.video-js .vjs-loading-spinner {
    border-color: #47d4ff
}

.video-js {
    font-size: 10px;
    color: #00d8ff
}

.vjs-emiliosalas-skin .vjs-big-play-button {
    font-size: 2.5em;
    line-height: 2.5em;
    height: 2.6em;
    width: 2.8em;
    border: .06666em solid #fff;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    margin-left: -1.40em;
    margin-top: -1.30em
}

.video-js .vjs-play-progress {
    background-color: #ffffff;
}

.video-js .vjs-play-progress:before {
    font-size: 1.1em;
    position: absolute;
    right: -0.5em;
    top: -0.333333333333333em;
    z-index: 1;
}

.vjs-resolution-button {
    color: #ccc;
    font-family: VideoJS
}

.vjs-resolution-button .vjs-resolution-button-staticlabel:before {
    content: '\f110';
    font-size: 1.8em;
    line-height: 1.67
}

.video-js .vjs-control-bar {
    background-color: rgb(13 16 40 / 70%);
}

.vjs-icon-share {
    font-family: VideoJS;
    font-weight: normal;
    font-style: normal;
}

.vjs-icon-share:before {
    content: "\f10f";
}

.vjs-resolution-button {
    color: #47d4ff;
    font-family: VideoJS
}

.video-js.vjs-videojs-share_open .vjs-modal-dialog .vjs-close-button {
    position: absolute;
    right: 0;
    top: 5px;
    width: 30px;
    height: 30px;
    color: #ff0045;
    cursor: pointer;
    opacity: 1.0;
    transition: opacity 0.25s ease-out
}

.video-js.vjs-videojs-share_open .vjs-modal-dialog .vjs-close-button:before {
    content: '&#215;';
    font-size: 20px;
    line-height: 15px;
    color: #fff
}

.video-js .vjs-share__title {
    align-self: center;
    font-size: 14px;
    color: #ff0045
}

.video-js .vjs-share__subtitle {
    width: 100%;
    margin: 0 auto 12px;
    font-size: 11px;
    color: #fff;
    opacity: 0.7
}

.video-js .vjs-share__short-link-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: 40px;
    margin: 0 auto;
    margin-bottom: 11px;
    border: 0;
    color: rgba(255, 255, 255, 0.65);
    background-color: #363636;
    outline: none;
    overflow: hidden;
    flex-shrink: 0
}

.video-js .vjs-share__btn {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    padding: px;
    border: 0;
    color: #fff;
    background-color: #2e2e2e;
    background-size: 18px 19px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    outline: none;
    transition: width 0.3s ease-out, padding 0.3s ease-out
}

.video-js .vjs-share__btn svg {
    flex-shrink: 0
}

.video-js .vjs-share__btn span {
    position: relative;
    padding-left: 10px;
    opacity: 0;
    transition: opacity 0.3s ease-out
}

.video-js .vjs-share__social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    margin-right: 6px;
    margin-bottom: 1px;
    cursor: pointer;
    font-size: 8px;
    transition: transform 0.3s ease-out, filter 0.2s ease-out;
    border: none;
    outline: none
}

.video-js .vjs-share__social:hover {
    filter: brightness(115%)
}

.video-js .vjs-share__social svg {
    width: 100%;
    max-height: 14px
}

.video-js .vjs-share__social_mail {
    background-color: #ff0048
}

.video-js .vjs-share__social_tw {
    background-color: #000000
}

.video-js .vjs-share__social_reddit {
    background-color: #ff4500
}

.video-js .vjs-share__social_fbFeed {
    background-color: #47d4ff
}

.video-js .vjs-share__social_messenger {
    background-color: #000000
}

.video-js .vjs-share__social_gp {
    background-color: #47d4ff
}

.video-js .vjs-share__social_telegram {
    background-color: #47d4ff
}

.video-js .vjs-share__social_whatsapp {
    background-color: #000000
}

div.video-js .vjs-overlay {
    font-size: 10px;
    width: auto;
    height: auto;
    background: rgba(0, 0, 0, 0.3);
}

.vjs-contextmenu-ui-menu {
    position: absolute
}

.vjs-contextmenu-ui-menu .vjs-menu-content {
    background-color: #2b333f;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: .3em;
    padding: .25em
}